
global.jQuery = $;//require("jquery");
global.$ = $;

import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start()

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap")
require("bootstrap-multiselect")

import { CarReservations } from 'packs/frontend/car_reservations';
import { Home } from 'packs/frontend/home';

$(document).on("turbolinks:load", function(){
  $(".messageBar").last().delay(3000).fadeOut();
  Home.initialize() 
  CarReservations.initialize() 
  if ($("#login-box #box.registration").length) {
    $(".has-multiselect").multiselect({
      enableFiltering: true,
      includeFilterClearBtn: false,
      buttonWidth: "100%",
      nonSelectedText:    $(".has-multiselect").attr("data-nonselectedtext"),
      selectAllText:      $("#I18n").attr("data-multiselect-selectAllText"),
      nSelectedText:      $("#I18n").attr("data-multiselect-nSelectedText"),
      allSelectedText:    $("#I18n").attr("data-multiselect-allselectedtext"),
      filterPlaceholder:  $("#I18n").attr("data-multiselect-filterPlaceholder")
    })
  }
})

function notify(notification, status = 'info') {
  $("body").append("<div class='alert-"+status+" messageBar admin_notice alert'>"+notification+"</div>");
  $(".messageBar").last().delay(3000).fadeOut();
}

window.notify = notify